import i18next from "i18next";
import i18n_fr from "./fr.json"
import i18_en from "./en.json"

export function languageInitialization(){
    if(!localStorage.getItem("lang")|localStorage.getItem("lang")==="") localStorage.setItem("lang",navigator.language.split("-")[0])
    i18next.init({
        compatibilityJSON: 'v4',
        lng:localStorage.getItem("lang"),
        fallbackLng:"en",
        debug:true,
        resources:{
            fr:{
                translation:i18n_fr
            },
            en:{
                translation:i18_en
            }
        }
    })
};